<template>
    <v-container
        fluid
        class="mt-2"
    >
        <v-row __no-gutters>
            <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
            >
                <v-card flat>
                    <v-img :src="getCoursePlanImage()"></v-img>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
            >
                <v-card
                    v-for="(item,i) in cnews"
                    :key="i"
                    class="mx-auto mt-4"
                >

                    <v-card-title class="orange lighten-5">{{item.title}}
                    </v-card-title>
                    <v-card-subtitle class="pb-0 orange lighten-5">
                        {{format_date(item.news_date)}}

                    </v-card-subtitle>


                    <v-card-text class="text--primary mt-2">
                        <div>{{item.text}}</div>

                    </v-card-text>


                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import cplanData from "@/data/course_plan_data.json"

    export default {
        data: () => ({
            cplan: cplanData.data,
            cnews: cplanData.news,
        }),
        created() {
            this.cplan.sort(function (a, b) {
                return new Date(a.from).getTime() - new Date(b.from).getTime();
            });
        },

        methods: {
            format_date(value) {
                if (value) {
                    return moment(String(value)).format('DD.MM.YYYY')
                }
            },
            getCoursePlanImage() {
                var currentDate = new Date();
                var from;
                var inx;

                this.cplan.forEach((item, i) => {
                    from = new Date(item.from)
                    inx = i;
                    if (currentDate < from) {
                        return true;
                    }
                });
                if (inx === this.cplan.length) {
                    // console.log("# currentCouesPlan is: ", this.cplan[inx].from, inx)
                    return require(`@/assets/img/courseplan/${this.cplan[inx].image}`)
                } else {
                    // console.log("# currentCouesPlan is: ", this.cplan[inx - 1].from, inx)
                    return require(`@/assets/img/courseplan/${this.cplan[inx - 1].image}`)

                }
            },

            getAvatarImgUrl(img) {

                return require(`@/assets/img/trainer/${img}`)
            },

            getCourseImgUrl(img) {
                return require(`@/assets/img/courses/${img}`)
            },

            goBack() {
                return this.$router.go(-1)
            }

        },
    }
</script>

<style>
    .v-treeview-node__root {
        height: 60px;
    }
</style>