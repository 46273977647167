let appConfig;

if (process.env.NODE_ENV === "production") {
    appConfig = {
        $api_url: "https://api.xxx.com",
        $content_type: "application/json",
        timeoutDuration: 30000,
        someOtherProps: 'xyz'
    };
} else {
    appConfig = {
        $api_url: "http://localhost:4300/",
        $home_data: "@/assets/img/home/",
        $content_type_img: "multipart/form-data",
        $content_type_data: "application/json",
        timeoutDuration: 1000,
        someOtherProps: 'abc'
    };
}

export { appConfig }