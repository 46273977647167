<template>
    <v-container
        fluid
        class="mt-0"
    >
        <v-card flat>
            <v-card-title>
                <v-col
                    class=""
                    cols="2"
                    lg="1"
                    sm="2"
                    xs="1"
                >
                    <v-btn
                        class="mx-0"
                        elevation="0"
                        fab
                        small
                        @click="goBack()"
                    >
                        <v-icon dark>
                            mdi-arrow-left
                        </v-icon>
                    </v-btn>

                </v-col>
                <v-col
                    class=""
                    cols="10"
                    lg="11"
                    sm="10"
                    xs="11"
                >
                    <h5>Trainerinnen und Trainer</h5>
                </v-col>
            </v-card-title>


            <!-- Trainer -->
            <v-responsive>
                <v-expansion-panels
                    elevation="0"
                    hover
                    multiple
                >
                    <v-expansion-panel
                        v-for="(item,i) in items"
                        :key="i"
                        class="my-1"
                    >
                        <v-expansion-panel-header
                            color="orange lighten-5"
                            class="pt-0 pb-0 "
                        >
                            <v-col
                                class=""
                                xs="1"
                                sm="2"
                                md="1"
                                lg="1"
                            >
                                <v-avatar
                                    v-if="item.avatar"
                                    size="62"
                                >
                                    <v-img :src="getAvatarImgUrl(item.avatar)"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col
                                xs="11"
                                sm="10"
                                md="11"
                                lg="11"
                            >
                                {{item.name}}
                            </v-col>
                            <template v-slot:actions>
                                <v-icon color="orange lighten-1">
                                    mdi-arrow-down-drop-circle-outline
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>

                        <!-- Kurse eines Trainers -->
                        <v-expansion-panel-content>
                            <v-col cols="12">
                                <v-btn
                                    v-for="(course,i) in courses"
                                    :key="i"
                                    class="ma-2"
                                    fab
                                >
                                    <router-link
                                        :to="{ name: 'CourseView', params: { item: course }}"
                                        class="treenav"
                                    >
                                        <v-avatar size="62">
                                            <img :src="getCourseImgUrl(course.image)">
                                        </v-avatar>
                                    </router-link>
                                </v-btn>
                            </v-col>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisi
                            ut
                            aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-responsive>
        </v-card>

    </v-container>
</template>

<script>
    import teamData from "@/data/team_data.json"
    import courseData from "@/data/course_data.json"

    export default {
        data: () => ({
            text: 'bei dem der Fokus auf moderater Gewichtsbelastung und hoher Wiederholungszahl liegt. Du verbrennst dabei bis zu <strong>540 Kalorien^</strong>. Bei den <a data-id="8159" href="http://www.lesmills.com/de/wissen/aus-der-forschung/der-rep-effect/" target="_blank" title="DER REP-EFFECT">wissenschaftlich erprobten</a>',
            active: [],
            avatar: null,
            open: [],
            users: [],
            courses: courseData,

        }),

        computed: {
            items() {
                // console.log("# team:", teamData)
                return teamData
            },
            selected() {
                if (!this.active.length) return undefined

                const id = this.active[0]
                console.log(id, this.users, teamData, teamData.find(user => user.id === id));
                // return this.users.find(user => user.id === id)
                return teamData.find(user => user.id === id)
            },
        },

        watch: {
            selected: 'randomAvatar',
        },
        created() { console.log("# created ...") },
        methods: {

            getAvatarImgUrl(img) {

                return require(`@/assets/img/trainer/${img}`)
            },

            getCourseImgUrl(img) {
                return require(`@/assets/img/courses/${img}`)
            },

            goBack() {
                return this.$router.go(-1)
            }

        },
    }
</script>

<style>
    .v-treeview-node__root {
        height: 60px;
    }
</style>