<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid>
        <!-- <v-card flat>
          <v-row class="">
            <v-col
              cols="1"
              class="pl-4"
            >
              <v-app-bar-nav-icon
                v-if="$vuetify.breakpoint.mdAndDown"
                @click="drawer = !drawer"
              ></v-app-bar-nav-icon>
            </v-col>
            <v-col
              cols="2"
              class="mt-3"
            >
              <v-img
                src="@/assets/img/app/bc-logo.png"
                class="__ml-4"
              ></v-img>
            </v-col>
            <v-col
              cols="9"
              class=""
            >
              <v-tabs
                v-if="!$vuetify.breakpoint.mdAndDown"
                color="orange accent-4"
                right
                flat
                class="mb-4"
              >
                <v-tab
                  v-for="(m,i) in menu"
                  :key="i"
                >
                  <router-link
                    :to="{'path':m.route}"
                    class="treenav"
                  >{{m.label}}</router-link>
                </v-tab>

              </v-tabs>
            </v-col>
          </v-row>
        </v-card> -->

        <div
          class="pt-0"
          flat
        >
          <v-toolbar
            flat
            elevation="0"
            color="orange lighten-4"
          >

            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <!-- </v-col> -->
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="9"
              xs="12"
              class=""
            >
              <v-toolbar-title>
                <router-link
                  :to="{'path': '/'}"
                  class="treenav"
                >
                  <v-img
                    src="@/assets/img/app/bc-logo.png"
                    class="mt-2 logo"
                  ></v-img>
                </router-link>
              </v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              v-if="!$vuetify.breakpoint.mdAndDown"
              cols="9"
              lg="9"
              md="2"
              sm="9"
              xs="0"
              color="orange lighten-4"
            >

              <v-tabs
                background-color="orange lighten-4"
                color="orange accient-1"
                right
                flat
              >

                <v-tab
                  v-for="(m,i) in menu"
                  :key="i"
                >
                  <router-link
                    :to="{'path': m.route}"
                    class="treenav"
                  >{{m.label}}</router-link>
                </v-tab>

              </v-tabs>
            </v-col>
            <!-- </v-row> -->
          </v-toolbar>
          <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            color="orange lighten-5 text--accent-4"
          >
            <v-list
              nav
              dense
            >
              <v-img
                src="@/assets/img/app/bc-logo.png"
                class="mt-2 mb-3 logo"
              ></v-img>
              <v-list-item-group
                v-model="group"
                active-class="orange--text text--accent-4"
              >
                <v-list-item
                  v-for="(m,i) in menu"
                  :key="i"
                >
                  <v-list-item-title>
                    <router-link
                      :to="{'path': m.route}"
                      class="treenav"
                    >{{m.label}}</router-link>
                  </v-list-item-title>
                </v-list-item>


              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
          <router-view></router-view>
        </div>
      </v-container>

    </v-main>

    <!-- Footer -->
    <v-footer
      padless
      class="pa-4"
    >
      <v-row>
        <v-col
          align-self="end"
          class="text-center text-caption"
          cols="12"
        >
          &copy; {{ new Date().getFullYear() }} BEONE FITNESSCLUB GmbH
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      slogan: "... FOR A FITTER PLANET!",
      fab: false,
      hidden: false,
      tabs: null,
      drawer: false,
      menu: [
        { label: "CLUB", route: "/home" },
        { label: "KURSE", route: "/course-list-view" },
        { label: "KURSPLAN", route: "courseplan-view" },
        { label: "NEWS", route: "" },

      ],

    }),
    mounted() {
      console.log("# breakpoint-Name:", this.$vuetify.breakpoint.name)
    },

    computed: {
      activeFab() {
        // switch (this.tabs) {
        //   case 'one': return { color: 'success', icon: 'mdi-share-variant' }
        //   case 'two': return { color: 'red', icon: 'mdi-pencil' }
        //   case 'three': return { color: 'green', icon: 'mdi-chevron-up' }
        //   default: return {}

        // }
        return null;
      },
    },
  }
</script>

<style>
  .bc_container {
    background-color: orange lighten-5 !important;
  }

  .logo {
    min-width: 100px;
    max-width: 160px;
  }

  .treenav {
    text-decoration: none !important;
    color: inherit !important;
  }

  .exact-active {
    background-color: #ffeee1;
  }
</style>