<template>
    <v-container
        fluid
        class="mt-0"
    >
        <v-card
            class="mx-auto"
            flat
        >
            <div v-if="!item.title_image_desktop">
                <v-btn
                    class="mx-2  align-top"
                    elevation="0"
                    fab
                    small
                    @click="goBack()"
                >
                    <v-icon dark>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
                {{item.label}}:<small> Beschreibung nicht vorhanden</small>
            </div>

            <v-img
                v-if="item.title_image_desktop"
                class="white--text align-start pt-2"
                max-height="500px"
                :src="titleImage"
            >
                <v-btn
                    class="mx-2  align-top"
                    elevation="0"
                    fab
                    small
                    @click="goBack()"
                >
                    <v-icon dark>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>

                <v-card-title>{{item.label}}</v-card-title>
                <v-card-subtitle>{{item.title}}</v-card-subtitle>
            </v-img>

            <!-- <v-card-text class="text--primary">
                <div v-html="item.description">
                </div>
            </v-card-text> -->
            <br>
            <v-card-text>
                <v-row
                    no-gutters
                    dense
                >
                    <v-col
                        cols="12"
                        xl="4"
                        lg="6"
                        md="6"
                        sm="12"
                        xs="12"
                    >
                        <v-card-text class="text--primary">
                            <div v-html="item.description">
                            </div>
                        </v-card-text>

                    </v-col>
                    <v-col
                        cols="12"
                        xl="4"
                        lg="6"
                        md="6"
                        sm="12"
                        xs="12"
                    >
                        <v-card-text class="text--primary">
                            <div v-html="item.motivation">
                            </div>
                        </v-card-text>

                    </v-col>
                    <v-col
                        cols="12"
                        xl="4"
                        lg="6"
                        md="6"
                        sm="12"
                        xs="12"
                    >
                        <v-card-text class="text--primary">
                            <div v-html="item.results">
                            </div>
                        </v-card-text>
                    </v-col>
                    <v-col
                        cols="12"
                        xl="4"
                        lg="6"
                        md="6"
                        sm="12"
                        xs="12"
                    >
                        <v-card-text class="text--primary">
                            <div v-html="item.training">
                            </div>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-card-actions>
                <v-btn
                    color="orange"
                    text
                >
                    Share
                </v-btn>

                <v-btn
                    color="orange"
                    text
                >
                    Explore
                </v-btn>
            </v-card-actions> -->


        </v-card>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            item: "",

        }),
        computed: {
            titleImage() {
                var img;
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        img = this.item.title_image_mobile;
                        return this.getTitleImage(img)
                    case 'sm':
                        img = this.item.title_image_desktop;
                        console.log("# image: ", img);
                        return this.getTitleImage(img)
                    case 'md':
                        img = this.item.title_image_desktop
                        return this.getTitleImage(img)
                    case 'lg':
                        img = this.item.title_image_desktop
                        return this.getTitleImage(img)
                    case 'xl':
                        img = this.item.title_image_desktop
                        return this.getTitleImage(img)
                }

                return this.getTitleImage(img)
            },
        },
        mounted() {
            console.log("# param:", this.$route.params)
            this.item = this.$route.params.item;
        },
        methods: {
            goBack() {
                return this.$router.go(-1)
            },
            getTitleImage(img) {
                console.log("# getTitleImage:", img)
                return require(`@/assets/img/courses/${img}`)
            },
            getCourseImgUrl(img) {
                return require(`@/assets/img/courses/${img}`)
            }

        },
    }
</script>