import HomeView from '@/components/HomeView.vue';
import TeamView from '@/components/TeamView.vue';
import CourseListView from '@/components/CourseListView.vue';
import CourseView from '@/components/CourseView.vue';
import CoursePlanView from '@/components/CoursePlanView.vue';
import CommingsoonView from '@/components/CommingSoon.vue';
// import AppsDetails from '@/components/apps/AppsDetails.vue';
// import UsersAdm from '@/components/users/UsersAdm.vue';
// import UsersDetails from '@/components/users/UsersDetails.vue';

const routes = [
    { path: '/', name: "Home", component: HomeView },
    { path: '/course-list-view', name: "CourseList", component: CourseListView },
    { path: '/course-view/:item', name: "CourseView", component: CourseView },
    { path: '/courseplan-view', name: "CoursePlanView", component: CoursePlanView },
    { path: '/team-view', name: "TeamView", component: TeamView },
    { path: '/gallery-view', name: "GalleryView", component: CommingsoonView },
    { path: '/credit-note-view', name: "GutscheinView", component: CommingsoonView },
    { path: '/credit-note-view', name: "CreditNoteView", component: CommingsoonView },
    { path: '/price-view', name: "PriceView", component: CommingsoonView },
    { path: '/approach-view', name: "ApproachView", component: CommingsoonView },
    { path: '/business-hours-view', name: "BusinessHoursView", component: CommingsoonView },

    //     { path: '/apps-details', name: "AppsDetails", component: AppsDetails },
    //     { path: '/users-adm', name: "UsersAdm", component: UsersAdm },
    //     { path: '/users-details', name: "UsersDetails", component: UsersDetails },
];

// const routes = [];

export default routes;