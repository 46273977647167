<template>
    <v-container
        fluid
        class="mt-0"
    >
        <v-card flat>
            <v-card-title class="title">
                <v-col
                    class=""
                    cols="2"
                    lg="1"
                    sm="2"
                    xs="1"
                >
                    <v-btn
                        class="my-0"
                        elevation="0"
                        fab
                        small
                        @click="goBack()"
                    >
                        <v-icon dark>
                            mdi-arrow-left
                        </v-icon>
                    </v-btn>

                </v-col>
                <v-col
                    class=""
                    cols="10"
                    lg="11"
                    sm="10"
                    xs="11"
                >
                    <h5>Kurse</h5>
                </v-col>
            </v-card-title>


            <!-- Trainer -->
            <v-responsive>
                <v-expansion-panels
                    elevation="0"
                    hover
                    multiple
                >
                    <v-expansion-panel
                        v-for="(item,i) in items"
                        :key="i"
                        class="my-1"
                    >
                        <v-expansion-panel-header
                            color="orange lighten-5"
                            class="pt-0 pb-0 "
                        >
                            <v-col
                                class=""
                                xs="1"
                                sm="2"
                                md="1"
                                lg="1"
                            >
                                <v-avatar
                                    v-if="item.image"
                                    size="62"
                                >
                                    <v-img :src="getCourseImgUrl(item.image)"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col
                                xs="11"
                                sm="10"
                                md="11"
                                lg="11"
                            >
                                {{item.label}}
                            </v-col>
                            <template v-slot:actions>
                                <v-icon color="orange lighten-1">
                                    mdi-arrow-down-drop-circle-outline
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>

                        <!-- Beschreibung des Kurses -->
                        <v-expansion-panel-content>
                            <v-card
                                class="mx-auto"
                                flat
                            >
                                <v-card-text>
                                    <v-row
                                        no-gutters
                                        dense
                                    >
                                        <v-col
                                            cols="12"
                                            xl="4"
                                            lg="6"
                                            md="6"
                                            sm="12"
                                            xs="12"
                                        >
                                            <v-card-text
                                                class="text--primary"
                                                color="red"
                                            >
                                                <div v-html="item.description">
                                                </div>
                                            </v-card-text>

                                        </v-col>
                                        <v-col
                                            cols="12"
                                            xl="4"
                                            lg="6"
                                            md="6"
                                            sm="12"
                                            xs="12"
                                        >
                                            <v-card-text class="text--primary">
                                                <div v-html="item.results">
                                                </div>
                                            </v-card-text>

                                        </v-col>
                                        <v-col
                                            cols="12"
                                            xl="4"
                                            lg="6"
                                            md="6"
                                            sm="12"
                                            xs="12"
                                        >
                                            <v-card-text class="text--primary">
                                                <div v-html="item.motivation">
                                                </div>
                                            </v-card-text>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            xl="4"
                                            lg="6"
                                            md="6"
                                            sm="12"
                                            xs="12"
                                        >
                                            <v-card-text class="text--primary">
                                                <div v-html="item.training">
                                                </div>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-responsive>
        </v-card>

    </v-container>
</template>

<script>
    import courseData from "@/data/course_data.json"

    export default {
        data: () => ({
            active: [],
            avatar: null,
            open: [],
            users: [],
            courses: courseData,

        }),

        computed: {
            items() {
                return courseData
            },
            // selected() {
            //     if (!this.active.length) return undefined

            //     const id = this.active[0]
            //     console.log(id, this.users, teamData, teamData.find(user => user.id === id));
            //     // return this.users.find(user => user.id === id)
            //     return teamData.find(user => user.id === id)
            // },
        },

        methods: {

            getAvatarImgUrl(img) {
                // console.log("# getAvatarImgUrl(img):", img)
                return require(`@/assets/img/courses/${img}`)
            },

            getCourseImgUrl(img) {
                // console.log("# getCourseImgUrl(img):", img)
                return require(`@/assets/img/courses/${img}`)
            },

            goBack() {
                return this.$router.go(-1)
            }

        },
    }
</script>

<style>
    .v-treeview-node__root {
        height: 60px;
    }


    .title {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;

    }
</style>