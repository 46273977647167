<template>
    <v-container
        fluid
        class="mt-2"
    >

        <!-- <v-row
            no-gutters
            ___style="max-height: 768px;"
            class=""
        > -->
        <v-col
            ___cols="11"
            cols="12"
            align-self="start"
        >
            <div ___style="max-width: 350px;">
                <carousel
                    v-show="imgList.length"
                    :autoplay="true"
                    :scrollPerPage="true"
                    :perPageCustom="[[200, 1], [480, 1], [768, 1]]"
                    :minSwipeDistance="1"
                >

                    <slide
                        style="___width: 100%"
                        class=""
                        ___v-for="(item,i) in homeData"
                        v-for="(item,i) in filteredImages"
                        :key="i"
                    >
                        <v-img
                            ___src="https://picsum.photos/1980/1024/"
                            __src="https://via.placeholder.com/1980x1024"
                            :src="getImgUrl(item.src)"
                            ___style="width: 100%; ___height: 1024px; max-width: 100%;"
                        ></v-img>
                    </slide>

                </carousel>

            </div>

            <!-- <v-carousel>
                <v-carousel-item
                    v-for="(item,i) in filteredImages"
                    :key="i"
                    ___:src="item.src"
                    :src="getImgUrl(item.src)"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                ></v-carousel-item>
            </v-carousel> -->
        </v-col>
        <!-- </v-row> -->
        <v-container class="__mt-1">
            <v-row
                no-gutters
                class=""
            >
                <v-col
                    v-for="(m,i) in menu"
                    v-bind:key="i"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="4"
                    lg="4"
                    class=""
                >
                    <router-link
                        :to="{'path': m.route}"
                        class="treenav"
                    >

                        <v-card
                            class="ma-2"
                            style="__height: 200px; ___width: 100%; background-color:lightgray; ___border-radius: 5%!important;"
                            outlined
                            tile
                        >

                            <!-- <v-card-title class="justify-center">{{m.label}}</v-card-title> -->
                            <v-img
                                height="100%"
                                :src="getImgUrl(m.image)"
                            ></v-img>

                            <!-- <v-card-actions class="justify-center">
                            <v-btn
                                outlined
                                rounded
                                text
                            >
                                Button
                            </v-btn>
                        </v-card-actions> -->
                        </v-card>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';
    import { appConfig } from '../../appConfig';
    import homeData from "@/data/home_data.json"
    export
        default
        {
            name: 'home-component',

            components: {
                'carousel': Carousel,
                'slide': Slide
            },

            data: () => ({
                items: [
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                    },
                ],
                imgList: homeData.images,
                imgUrl: appConfig.$home_data,
                menu: [
                    { label: 'Team', image: 'btn_team.png', route: "/team-view" },
                    { label: 'Gallerie', image: 'btn_gallerie.png', route: "/gallery-view" },
                    { label: 'Gutschein', image: 'btn_gutschein.png', route: "/credit-note-view" },
                    { label: 'Preise', image: 'btn_preise.png', route: "/price-view" },
                    { label: 'Clubzeiten', image: 'btn_clubzeiten.png', route: "/business-hours-view" },
                    { label: 'Anfahrt', image: 'btn_anfahrt.png', route: "/approach-view" },
                ]

            }),
            computed: {
                columns() {
                    if (this.$vuetify.breakpoint.xl) {
                        return 4;
                    }
                    if (this.$vuetify.breakpoint.lg) {
                        return 3;
                    }

                    if (this.$vuetify.breakpoint.md) {
                        return 2;
                    }
                    return 1;
                },
                filteredImages() {
                    var list = [];
                    var from, to, now = new Date();
                    this.imgList.forEach((item) => {
                        from = new Date(item.from)
                        to = new Date(item.to)
                        if (from <= now && to >= now) {
                            list.push(item)
                        }
                    });
                    return list;
                },
            },
            created() {
            },
            methods: {
                getImgUrl(img) {
                    return require(`@/assets/img/home/${img}`)
                }

            },
        }
</script>

<style>
    .VueCarousel-slide {
        visibility: visible;
        flex-basis: 100%;
        width: 100%;

    }

    .img1 {
        height: 90vh;
    }

    .bc-corousel {
        min-height: 90vh !important;

    }
</style>