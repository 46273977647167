<template>
    <v-container
        fluid
        class="mt-0"
    >
        <v-card flat>

            <v-card-title>
                <v-col
                    class=""
                    cols="3"
                    lg="1"
                    sm="2"
                    xs="1"
                >

                    <v-btn
                        class="mx-2"
                        elevation="0"
                        fab
                        small
                        @click="goBack()"
                    >
                        <v-icon dark>
                            mdi-arrow-left
                        </v-icon>
                    </v-btn>

                </v-col>
                <v-col
                    class=""
                    cols="9"
                    lg="11"
                    sm="10"
                    xs="11"
                >Comming soon ...</v-col>
            </v-card-title>
        </v-card>
        <!-- <h1 class="white grey--text">Comming soon ...</h1> -->
    </v-container>
</template>

<script>
    export default {
        data: () => ({

        }),


        methods: {
            goBack() {
                return this.$router.go(-1)
            }

        },
    }
</script>